import React from "react";
// import gsap from "gsap";
import { css } from "@emotion/core";
import { useSpring, animated } from "react-spring";

// Components
import styled from "@emotion/styled";
import Container from "../Layouts/Container";
import TitleMain from "../Base/titles/TitleMain";
import TextMain from "../Base/text/TextMain";

// Images
import ImgContentToggle3 from "../Images/Home/content-toggle/ImgContentToggle3";
import ImgContentToggleThumb3 from "../Images/Home/content-toggle-thumbs/ImgContentToggleThumb3";
import ImgFeatureGoldCrown from "../Images/Features/ImgFeatureGoldCrown";

import { mediaQueries } from "../../configs/_configSite";
import { LinkDonationExt } from "../Base/links/LinkDonation";

// Style
const Background = styled(animated.div)`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
`;

const Wrapper = styled.section`
  z-index: 10;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 100%;
  width: 100%;

  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: ${mediaQueries.md}px) {
    grid-template-rows: 1fr auto;
  }
`;

const CrownContainer = styled.div`
  width: 150px;
  margin: 0 auto;
  @media (max-width: ${mediaQueries.md}px) {
    width: 60px;
  }
`;

const Content = styled.section`
  position: relative;
  top: 8vh;
  grid-column: 5 / span 4;

  @media (max-width: ${mediaQueries.lg}px) {
    grid-column: 4 / span 6;
  }

  @media (max-width: ${mediaQueries.md}px) {
    grid-column: 3 / span 8;
  }

  @media (max-width: ${mediaQueries.xs}px) {
    grid-column: 1 / span 12;
  }
`;

// Data
const data = {
  title: "A Beautiful Vestige",
  thumbTitle: "The Brand",
  imageBackground: <ImgContentToggle3 />,
  content: [
    `The Brand, A Beautiful Vestige  Everyday Reminders  We know and believe that you are worth infinitely more than anything we have to sell...we will actively tell you so...But sometimes even we need to be reminded of how special we trully are.  That's why each product, each post, each picture we share is meant to remind us of that forgotten worth and encourge each other to great things! Is there someone you can encourage today? Would you like us to pray for you?`,
    <LinkDonationExt
      href="/"
      style={{
        display: "table",
        margin: "0 auto",
        position: "relative",
        float: "none"
      }}
    >
      Watch Now
    </LinkDonationExt>
  ],
  imageThumb: <ImgContentToggleThumb3 />,
  color: "white"
};

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 50}px,${y / 50}px,0) scale(1.1)`;

export default () => {
  const [pops, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 2, tension: 250, friction: 140 }
  }));

  return (
    <Container
      vCenter
      hCenter
      height="100vh"
      fluid
      background="#ffffff"
      cssProp="overflow: hidden;"
    >
      <Wrapper
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <Content>
          <CrownContainer>
            <ImgFeatureGoldCrown />
          </CrownContainer>

          <TitleMain
            css={css`
              text-align: center;
              ${data.color !== "" ? `color: ${data.color}` : ""}
            `}
          >
            {data.title}
          </TitleMain>
          <TextMain
            css={css`
              max-width: 90%;
              margin: 0 auto;
              ${data.color !== "" ? `color: ${data.color};` : ""}
                @media (max-width: ${mediaQueries.md}px) {
                  font-size: 1.25rem;
                }
                @media (max-width: ${mediaQueries.sm}px) {
                  font-size: 1rem;
                }
            `}
          />
          {data.content.map(
            item =>
              (typeof item === "string" && (
                <TextMain
                  key={item}
                  css={css`
                    max-width: 90%;
                    margin: 0 auto;
                    ${data.color !== "" ? `color: ${data.color};` : ""}
                      @media (max-width: ${mediaQueries.md}px) {
                        font-size: 1.25rem;
                      }
                      @media (max-width: ${mediaQueries.sm}px) {
                        font-size: 1rem;
                      }
                  `}
                >
                  {item}
                </TextMain>
              )) ||
              (typeof item !== "string" && item)
          )}
        </Content>
      </Wrapper>
      <Background
        key={data.title}
        style={{ transform: pops.xy.interpolate(trans1) }}
        id="content-toggle-background-0"
        className="content-toggle-background"
        css={css`
          opacity: 1;
        `}
      >
        {data.imageBackground}
      </Background>
      ))
    </Container>
  );
};
